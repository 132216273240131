import styled from 'styled-components';
import colors from '../styles/colors';

// TEXTS
export const TitleMain = styled.h1`
  font-weight: normal;
  font-size: 2.5rem;
  color: ${({theme})=> theme.colors.onBackground};
  font-family: 'Poppins-Bold';
  @media (max-width: 1000px) {
    font-size: 2rem;
	}
  `;
export const TextMenu = styled.h3`
  font-family: 'Poppins-Bold';
  font-size: 1rem;
  color: ${({theme,selected, primaryColor})=> selected ? primaryColor ?? theme.colors.primary : theme.colors.onBackground};
`;
export const TitleSection = styled.h2`
  font-family: 'Poppins-Bold';
  font-size: 1.5rem;
  color: ${({theme, color})=> color || theme.colors.primary};
`;
export const SubTitleSection = styled.h4`
  font-family: 'Poppins-Bold';
  font-size: 2rem;
  color: ${({theme})=> theme.colors.onBackground};
`;
export const Text = styled.p`
  font-family: ${({bold})=> bold ? 'Poppins-Bold' : 'Poppins'};
  font-size: 1.2rem;
  color: ${({theme})=> theme.colors.text};
  line-height: 1.5rem;
  margin:0;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  color: ${({theme})=> theme.colors.text};
`;
export const TextLogo = styled.h3`
  font-size: 1.5rem;
  color: ${({theme,color})=> color|| theme.colors.textSection};
  font-family: 'Poppins-Bold';
  margin-left:5px;
  @media (max-width: 400px) {
  font-size: 1.2rem;
	}
`;

// CONTAINERS
export const Container = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  padding: 0px 10%;
  @media (max-width: 770px) {
    padding: 0px 6%;
	}
  background: ${({theme})=> theme.colors.background};
  color: ${({theme})=> theme.colors.onBackground};
  .video-youtube {
    width: 100%;
    height: calc(100vw/2.3);
  }
`;
export const Benefit = styled.div`
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-top: 5px;
 margin-bottom: 5px;
 padding: 20px 40px;
 border-radius: 10;
 background-color: ${({theme})=> theme.colors.surface};
`;
export const BlogContainer = styled.div`
  display:flex;
  flex:1;
  flex-direction:column;
  margin: 3rem 10%;

  .input-search{
    border-radius:8px;
    padding: 5px 12px;
    border: 3px solid ${({theme})=> theme.colors.surface};
    background:   ${({theme})=> theme.colors.background};
    color:   ${({theme})=> theme.colors.text};
    font-size: 16px;
  }
  .input-search:focus{
    outline-color: ${({theme})=> theme.colors.primary} !important;
  }
  .title-blog{
    font-size:1.4rem;
  }
  .text-description{
    color: ${({theme})=> theme.colors.text};
    font-size:1rem;
    line-height:1.3rem;
  }
  main{
    display:flex;
    align-items:center;
    justify-content: center;
    margin-bottom: 1.3rem;
  }
  .border{
    border: 1px solid ${({theme})=> theme.colors.surface};
    margin: 1.5rem 0;
  }
  .tags{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 0;
  }

  .posts{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .title-post{
    &:hover{
      transition: 0.4s;
      opacity:0.8;
      cursor:pointer;
    }
  }
  .post{
    margin-top:2rem;
    width: 45%;
  }
  .post-content{
    color: ${({theme})=> theme.colors.text};
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  .text-date{
    color: ${({theme})=> theme.colors.onSurface};
    text-align: right;
  }
  .see-more{
    margin-top:2.5rem;
    background: ${({theme})=> theme.colors.background};
    border: 3px solid ${({theme})=> theme.colors.onBackground};
    color: ${({theme})=> theme.colors.onBackground};
    font-family: 'Poppins-Bold';
    border-radius: 5px;
    cursor:pointer;
    padding: 5px 0;
    box-shadow: 0px 0px 10px 1px ${({theme})=> theme.colors.surface};
  }
  @media (max-width: 770px) {
    margin: 3rem 0;
    .post{
      width: 100%;
    }
  }
`;
export const Tag = styled.h5`
  background: ${({active,theme})=> active ? theme.colors.primary: theme.colors.surface};
  border-radius: 7px;
  padding: 10px 15px;
  font-size: 1rem;
  margin-top:10px;
  color:  ${({active,theme})=> active ? theme.colors.white: theme.colors.onBackground};
  &:hover{
    cursor: pointer;
  }
  margin-left: 10px;
`;
export const Footer = styled.footer`
  display:flex;
  flex:1;
  background: ${({theme})=> theme.colors.degrade};
  align-items:center;
  justify-content:center;
  padding: 15px;
`;
export const Row = styled.div`
  display:flex;
  flex-direction:row;
`;
export const Main = styled.div`
  display:flex;
  flex:1;
  flex-direction:row;
  justify-content: space-between;
  align-items:center;
  padding: 50px 0px;
  @media (max-width: 560px) {
    margin-top:2rem;
    flex-direction:column;
  }

  .icon-store{
    height: 30px;
    @media (max-width: 900px) {
      height: 15px;
	  }
  }
  .img-main{
    height:430px;
    @media (max-width: 900px) {
      height: 400px;
    }
    @media (max-width: 700px) {
      height: 330px;
    }
    @media (max-width: 560px) {
      height: 230px;
      margin-top:2rem;
    }
  }
  `;
export const RowAndSpaceBetween = styled.div`
  display:flex;
  flex:1;
  flex-direction:row;
  justify-content: space-between;
  .img-func{
    max-height:400px;
    max-width:350px;
    object-fit: contain;
    @media (max-width: 900px) {
      max-height:300px;
      max-width:260px;
    }
  }
  @media (max-width: 700px) {
      flex-direction: ${({reverseWrap})=> reverseWrap? 'column-reverse' : 'column'};
      align-items:center;
    }

  .button-vermais:hover{
    opacity: 0.9;
  }
  .button-vermais{
    background: ${colors.primary};
    color: white;
    padding: 8px;
    /* color: ${colors.primary}; */
    font-family: 'Poppins-Bold';
    margin-top:12px;
    font-size: 15px;
    cursor:pointer;
    width: 190px ;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-bottom:15px;
  }
`;

// BUTTONS
export const Button = styled.a`
  background: ${({theme})=> theme.colors.primary};
  padding: 10px 20px;
  border-radius:10px;
  border:none;
  text-decoration:none; 
  box-shadow: 0px 0px 10px 1px ${({theme})=> theme.colors.surface};
  display:flex;
  justify-content: space-between;
  align-items:center;
  min-width:90px;
  &:hover{
    cursor: pointer;
    opacity: 0.9;
  }
  @media (max-width: 850px) {
    padding: 8px 10px;
	}
`;
export const TextButton = styled(Text)`
  color: ${({theme})=> theme.colors.white};
  font-weight:bold;
  margin-left:10px;
  font-size: 1rem;
  line-height:0.9rem;
  @media (max-width: 960px) {
    font-size: 0.9rem;
	}
  @media (max-width: 400px) {
    font-size: 0.75rem;
	}
`;


export const Card = styled.div`
  flex-wrap: wrap;
  background: ${({theme})=> theme.colors.surface};
  border-radius: 10px;
  padding: 10px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content:center;
  margin-bottom: 10px;
  ${({owner,theme}) =>
    owner &&
    `
      border-color: ${theme.colors.primary};
      border-width: 2;
      background-color: ${theme.colors.primary};
  `};
`;

export const Position = styled.h4`
  color: ${({owner,theme}) => (owner ? theme.colors.white : theme.colors.primary)};
  font-family: 'Poppins-Bold';
  font-size: 25px;
  padding: 0px 10px;
`;

export const Username = styled.h3`
  padding: 0 15px;
  font-family: 'Poppins-Bold';
  font-size: 20px;
  color: ${({owner,theme}) => (owner ?  theme.colors.surface : theme.colors.textNormal)};
  text-transform: capitalize;
  font-family: 'Poppins-Bold';
`;
export const Label = styled.h3`
  font-family: 'Poppins-Bold';
  font-size: 18px;
  margin-right:10px;
  margin-top:5px;
  color: ${({theme})=> theme.colors.onBackground};
`;  
export const Value = styled.h3`
  margin-top:5px;
  font-size: 18px;
  font-family: 'Poppins-Bold';
  color: ${({theme})=> theme.colors.primary};
`;
export const RowRanking = styled.div`
  display:flex;
  flex-direction:row;
  flex:1;
  justify-content:space-between;
  align-items:center;
`;